import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Endpoint } from '../constants/endpoint';
import { ContextKey } from '../constants/context';

import {
  ExternalDatasetRequestModel,
  ExternalDatasetResponseModel,
} from '../../models/external.model';

import { environment } from 'environment';

@Injectable({
  providedIn: 'root',
})
export class ExternalFetchService {
  constructor(private httpClient: HttpClient) {}

  public dataset(
    data: ExternalDatasetRequestModel
  ): Observable<ExternalDatasetResponseModel> {
    return this.httpClient.post<ExternalDatasetResponseModel>(
      Endpoint.Metabase.Dataset,
      data,
      {
        context: new HttpContext()
          .set(ContextKey.ApiEncryption, false)
          .set(ContextKey.XApiKey, environment.metabase.xApiKey),
      }
    );
  }
}
